import api from "./api";

export const getCustomerList = async () => {
  const response = await api.get("/customers");
  return response.data;
};

export const getACustomerList = async (email) => {
  const response = await api.get(`/customer?email=${email}`);
  return response.data;
};

export const createCustomerList = async (data) => {
  const response = await api.post("/admin/manage/customers/register", data);
  return response.data;
};

export const registerCustomer = async (data) => {
  const response = await api.post("/customer/register", data);
  return response.data;
};

export const linkDeviceToCustomer = async (data) => {
  const response = await api.post("/admin/manage/device/add", data);
  return response.data;
};

export const editCustomerList = async (data) => {
  const response = await api.patch("/admin/manage/customers/edit", data);
  return response.data;
};

export const deleteCustomerList = async (data) => {
  const response = await api.delete("/admin/manage/customers/delete", { data });
  return response.data;
};

export const getDeviceList = async () => {
  const response = await api.get("/devices");
  return response.data;
};

export const createDeviceList = async (data) => {
  const response = await api.post("/admin/manage/device/register", data);
  return response.data;
};

export const editDeviceList = async (data, id) => {
  const response = await api.patch(`/admin/manage/device/edit/${id}`, data);
  return response.data;
};

export const deleteDeviceList = async (data) => {
  const response = await api.delete("/admin/manage/device/delete", { data });
  return response.data;
};

export const getServiceList = async () => {
  const response = await api.get("/service/data");
  return response.data;
};

export const createServices = async (data) => {
  const response = await api.post("/admin/manage/service/add", data);
  return response.data;
};

export const editServiceList = async (data) => {
  const response = await api.patch("/admin/manage/service/edit", data);
  return response.data;
};

export const deleteServiceList = async (data) => {
  const response = await api.delete("/admin/manage/service/delete", { data });
  return response.data;
};

export const enableDevice = async (data) => {
  const response = await api.post("/device/enable_disable", data);
  return response.data;
};

export const enableTask = async (data) => {
  const response = await api.post("/task/enable_disable", data);
  return response.data;
};

export const getCustomerDetails = async () => {
  const response = await api.get("/customer");
  return response.data;
};

export const editCustomerDetails = async (data) => {
  const response = await api.patch("customer/edit", data);
  return response.data;
};

export const getCustomerNodes = async () => {
  const response = await api.get("/objects/get");
  return response.data;
};

export const createCustomerNodes = async (data) => {
  const response = await api.post("/objects/create", data);
  return response.data;
};

export const editCustomerNodes = async (data) => {
  const response = await api.patch("/objects/edit", data);
  return response.data;
};

export const deleteCustomerNodes = async (data) => {
  const response = await api.delete("/objects/delete", { data });
  return response.data;
};

export const getCustomerDevice = async () => {
  const response = await api.get("/customers/devices");
  return response.data;
};

export const createCustomerDevice = async (data) => {
  const response = await api.post("/device/activate", data);
  return response.data;
};

export const editCustomerDevice = async (data) => {
  const response = await api.patch("/devices/edit", data);
  return response.data;
};

export const deleteCustomerDevice = async (data) => {
  const response = await api.delete("/devices/delete", { data });
  return response.data;
};

export const getMonitorList = async (id) => {
  const response = await api.get(
    `/monitoring_tasks${id ? `?task_id=${id}` : ""}`
  );
  return response.data;
};

export const createMonitor = async (data) => {
  const response = await api.post("/monitoring_tasks", data);
  return response.data;
};

export const editMonitoringTask = async (data) => {
  const response = await api.patch("/update_monitoring_task/info", data);
  return response.data;
};

export const deleteMonitor = async (data) => {
  const response = await api.delete("/monitoring_tasks/delete", { data });
  return response.data;
};

export const postAFrequency = async (data) => {
  const response = await api.post("/monitor", data);
  return response.data;
};

export const getConfigureNodes = async (id) => {
  const response = await api.get(`/task_objects?task_id=${id}`);
  return response.data;
};

export const postANodeConfiguration = async (data) => {
  const response = await api.post("/task_objects", data);
  return response.data;
};

export const getMonitoringAlert = async (id) => {
  const response = await api.get(`/monitoring_alert?task_id=${id}`);
  return response.data;
};

export const getConfigureDeviceList = async (id) => {
  const response = await api.get(`/services/devices?task_id=${id}`);
  return response.data;
};

export const postADeviceConfiguration = async (data) => {
  const response = await api.post("/services/devices", data);
  return response.data;
};

export const updateMonitorTaskDescription = async (data) => {
  const response = await api.patch("/update_monitoring_task/description", data);
  return response.data;
};

export const getNotificationList = async (type) => {
  const response = await api.get(`/alerts/configured?alert_type=${type}`);
  return response.data;
};

export const createCustomerNotification = async (data) => {
  const response = await api.post("/alerts/device_service", data);
  return response.data;
};

export const updateCustomerNotification = async (data) => {
  const response = await api.patch("/alerts/device_service", data);
  return response.data;
};

export const deleteCustomerNotification = async (data) => {
  const response = await api.delete("/alerts/delete", { data });
  return response.data;
};

export const postNotification = async (data) => {
  const response = await api.post("/monitoring_alert/add", data);
  return response.data;
};

export const postDailyTask = async (data) => {
  const response = await api.post("/daily_tasks", data);
  return response.data;
};

export const getTaskValues = async (id) => {
  const response = await api.get(`/task_values?monitoring_id=${id}`);
  return response.data;
};

export const getIntervals = async (id) => {
  const response = await api.get(`/get_interval_data?monitoring_id=${id}`);
  return response.data;
};

export const getDashboard = async (id) => {
  const response = await api.get("/dashboard");
  return response.data;
};

export const logoutAPI = async () => {
  const response = await api.post("/auth/logout", { data: "" });
  return response.data;
};
