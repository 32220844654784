import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, InputGroup, Modal } from "react-bootstrap";
import Button from "../../../Components/Button";
import { createCustomerNodes, editCustomerNodes } from "../../../Service";

const initialForm = {
  name: "",
  type: "",
  value: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
  value: Yup.string().required("Value is required"),
});

const NodesPopup = (props) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (props.mode === "edit") {
      setForm(props.tableData);
      formik.setValues(props.tableData);
    }

    return () => {
      setForm(initialForm);
    };
  }, [props.mode, props.show]);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (props.mode === "add") {
        try {
          await createCustomerNodes(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      } else if (props.mode === "edit") {
        try {
          await editCustomerNodes(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  // function onChange(e) {
  //   const { name, value } = e.target;
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // }

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   if (props.mode === "add") {
  //     try {
  //       const data = await createCustomerNodes(form);
  //       props.added();
  //       props.onHide();
  //       setForm(initialForm);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else if (props.mode === "edit") {
  //     try {
  //       const data = await editCustomerNodes(form);
  //       props.added();
  //       props.onHide();
  //       setForm(initialForm);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Nodes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.name}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.name && formik.touched.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <InputGroup hasValidation>
              <Form.Select
                // value={form.type}
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.type && formik.touched.type}
              >
                <option value="">Select</option>
                <option value="Windows">Windows</option>
                <option value="Unix">Unix</option>
                <option value="IP Device">IP Device</option>
                <option value="Web Site">Web Site</option>
                <option value="FTP">FTP</option>
                <option value="Email">Email</option>
                <option value="Database">Database</option>
                <option value="Web API">Web API</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {formik.errors.type}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Value</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.value}
                name="value"
                value={formik.values.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.value && formik.touched.value}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.value}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <div className="d-flex justify-content-end mt-4" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NodesPopup;
