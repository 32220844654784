import React from "react";
import "./style.css";

const Button = ({ type, icon, label, onClick, buttonMode, size }) => {
  return (
    <>
      {icon ? (
        <button
          className={`button-common button-icon ${
            type === "primary" ? "primary" : "secondary"
          } ${size === "equal" ? "equal-button" : ""}`}
          onClick={onClick}
          type={buttonMode}
        >
          {icon && <img src={icon} alt="add" height={12} width={12} />}
          {label && <div>{label}</div>}
        </button>
      ) : (
        <button
          className={`button-common ${
            type === "primary" ? "primary" : "secondary"
          } large-button`}
          onClick={onClick}
          type={buttonMode}
        >
          {label}
        </button>
      )}
    </>
  );
};

export default Button;
