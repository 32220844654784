import React, { useEffect, useState } from "react";
import "./style.css";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { getNotificationList, postNotification } from "../../../../Service"; // Assuming postNotification sends the POST request

const NotificationPopup = (props) => {
  const [activePage, setActivePage] = useState("email");
  const [emailData, setEmailData] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState({
    email: [],
    sms: [],
  });

  // Populate data for email and SMS notifications
  useEffect(() => {
    const fetchEmailNotification = async () => {
      try {
        const data = await getNotificationList("Email");
        setEmailData(data.alerts);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSmsNotification = async () => {
      try {
        const data = await getNotificationList("SMS");
        setSmsData(data.alerts);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSmsNotification();
    fetchEmailNotification();
  }, []);

  // Populate the selected notifications based on props.data
  useEffect(() => {
    if (props.data) {
      const selectedEmailGroups = props.data
        .filter((alert) => alert.alert_type.toLowerCase() === "email")
        .reduce((acc, alert) => acc.concat(alert.group_name), []);

      const selectedSmsGroups = props.data
        .filter((alert) => alert.alert_type.toLowerCase() === "sms")
        .reduce((acc, alert) => acc.concat(alert.group_name), []);

      setSelectedNotifications({
        email: selectedEmailGroups,
        sms: selectedSmsGroups,
      });
    }
  }, [props.data, props.show]);

  // Handle checkbox change
  const handleCheckboxChange = (type, groupName) => {
    setSelectedNotifications((prev) => {
      const updated = { ...prev };
      if (updated[type].includes(groupName)) {
        // Remove if already selected
        updated[type] = updated[type].filter((item) => item !== groupName);
      } else {
        // Add if not selected
        updated[type].push(groupName);
      }
      return updated;
    });
  };

  // Handle form submission to save notifications
  const handleSubmit = async () => {
    const taskId = props.id;

    const requestBody = [
      ...selectedNotifications.email.map((group) => ({
        alert_type: "Email",
        group_name: group,
        task_id: taskId,
      })),
      ...selectedNotifications.sms.map((group) => ({
        alert_type: "Sms",
        group_name: group,
        task_id: taskId,
      })),
    ];

    try {
      await postNotification({ alert: requestBody, task_id: taskId }); // Assuming postNotification sends a POST request
      props.onHide(); // Close the modal
      props.success();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Notification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6">
            <h5 className="notification-title">Type of Notification</h5>
            <div className="notification-boxes" style={{ overflow: "hidden" }}>
              <div
                className={`notification-card-menu ${
                  activePage === "email" ? "notification-card-menu-active" : ""
                }`}
                onClick={() => setActivePage("email")}
              >
                Email
              </div>
              <div
                className={`notification-card-menu ${
                  activePage === "sms" ? "notification-card-menu-active" : ""
                }`}
                onClick={() => setActivePage("sms")}
              >
                SMS
              </div>
              <div
                className={`notification-card-menu ${
                  activePage === "beep" ? "notification-card-menu-active" : ""
                }`}
                onClick={() => setActivePage("beep")}
              >
                Beep
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <h5 className="notification-title">Notification</h5>
            <div className="notification-boxes scroll-height">
              <Form>
                {activePage === "email"
                  ? emailData.map((data) => (
                      <Form.Check
                        key={data.group_name}
                        type="checkbox"
                        label={data.group_name}
                        checked={selectedNotifications.email.includes(
                          data.group_name
                        )}
                        onChange={() =>
                          handleCheckboxChange("email", data.group_name)
                        }
                      />
                    ))
                  : activePage === "sms"
                  ? smsData.map((data) => (
                      <Form.Check
                        key={data.group_name}
                        type="checkbox"
                        label={data.group_name}
                        checked={selectedNotifications.sms.includes(
                          data.group_name
                        )}
                        onChange={() =>
                          handleCheckboxChange("sms", data.group_name)
                        }
                      />
                    ))
                  : null}
              </Form>
            </div>
          </div>
          <div className="col-lg-12 text-end mt-4">
            <Button
              type="primary"
              onClick={handleSubmit}
              label="Save"
              buttonMode="button"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationPopup;
