import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { Form } from "react-bootstrap";
import {
  deleteCustomerDevice,
  enableDevice,
  getCustomerDevice,
} from "../../../Service";
import MyDevicePopup from "./MyDevicePopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";

const MyDevice = () => {
  const [data, setData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [toggle, setToggle] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePage("my-device"));
  }, [dispatch]);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const data = await getCustomerDevice();
        setData(data.device_data);
        setStatistics(data.statistics);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeviceList();
  }, [toggle, isAdded]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "device_id",
        header: "Device Id",
        size: 80,
      },
      {
        accessorKey: "device_alias",
        header: "Alias",
      },
      {
        accessorKey: "task_count",
        header: "Tasks in 24 hrs",
      },
      {
        accessorKey: "device_status",
        header: "Status",
        Cell: ({ cell }) => (
          <span
            className={`${cell.getValue() === "Online" ? "online" : "offline"}`}
          >
            {cell.getValue()}
          </span>
        ),
      },
      {
        accessorKey: "created_at",
        header: "Registered Date",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "last_awake",
        header: "Last Awake",
        accessorFn: (row) => new Date(row.last_awake), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "end",
          minWidth: "100px",
          width: "120px",
        }}
      >
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch-1"
            checked={row.original.action === 1}
            label=""
            className=""
            onChange={async (e) => {
              await enableDevice({
                action: e.target.checked ? 1 : 0,
                device_id: row.original.device_id,
              });
              setToggle(!toggle);
            }}
          />
        </Form>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerDevice({
                    device_ids: [row.original.device_id],
                  });
                  setAlert(false);
                  added();
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{gap: '15px'}}>
        <Button
          icon={addIcon}
          label="Add"
          type="primary"
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.device_id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerDevice({ device_ids: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });
  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">My Devices</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>
      <MyDevicePopup
        show={modalShow}
        tableData={tableData}
        mode={mode}
        added={added}
        onHide={() => setModalShow(false)}
      />
      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </>
  );
};

export default MyDevice;
