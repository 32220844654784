import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { deleteMonitor, enableTask, getMonitorList } from "../../../Service";
import { Form } from "react-bootstrap";
import AlertBox from "../../../Components/AlertBox";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../Utils";

const Monitoring = () => {
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const dispatch = useDispatch();
  const goto = useNavigate();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getMonitorList();
        setData(data.tasks);
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(setActivePage("monitoring"));
    fetchCustomerList();
  }, [dispatch, isAdded, toggle]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "updated_at",
        header: "Last Update",
        accessorFn: (row) => new Date(row.updated_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "value",
        accessorFn: (row) =>
          `${row.completed_daily_tasks_count}/${row.total_daily_tasks_count}`,
        header: "Task Status",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "end",
          minWidth: "100px",
          width: "120px",
        }}
      >
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch-1"
            checked={row.original.enable}
            label=""
            className=""
            onChange={async (e) => {
              try {
                await enableTask({
                  action: e.target.checked ? 1 : 0,
                  task_id: row.original.task_id,
                });
                setToggle(!toggle);
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </Form>

        <Tooltip title="Configure">
          <IconButton
            onClick={() => {
              goto(`/client/monitoring-configure/${row.original.task_id}`);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  try {
                    await deleteMonitor({
                      task_ids: [row.original.task_id],
                    });
                    setAlert(false);
                    added();
                  } catch (error) {
                    console.log(error);
                  }
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{gap: '15px'}}>
        <Button
          onClick={() => {
            goto("/client/monitoring-add");
          }}
          icon={addIcon}
          label="Add"
          type="primary"
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.task_id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteMonitor({ task_ids: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Tasks</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>
      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </>
  );
};

export default Monitoring;
