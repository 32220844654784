import axios from "axios";
import { logoutAPI } from ".";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      username,
      password,
    });
    if (response.data.data.access_token) {
      localStorage.setItem("token", response.data.data.access_token);
      localStorage.setItem("user", response.data.data.role);
    }
    return response.data.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message, {
        position: "top-right",
      });
    } else {
      toast.error("An unexpected error occurred", {
        position: "top-right",
      });
    }
    // You can also throw the error if you want to handle it elsewhere
    throw error;
  }
};

const logout = async () => {
  await logoutAPI();
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return localStorage.getItem("user");
};

const getCurrentToken = () => {
  return localStorage.getItem("token");
};

const authService = {
  login,
  logout,
  getCurrentUser,
  getCurrentToken,
};

export default authService;
