import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import "./style.css";
import addIcon from "../../../Assets/Images/add-icon.svg";
import closeIcon from "../../../Assets/Images/close.svg";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../../../Components/Button";
import { createServices, editServiceList } from "../../../Service";

const initialForm = {
  service_name: "",
  parameters: [],
  return_type: "String",
};

const returnTypeOptions = [
  { label: "String", value: "String" },
  { label: "Integer", value: "Integer" },
  { label: "Boolean", value: "Boolean" },
];

const ServicePopup = (props) => {
  const [form, setForm] = useState(initialForm);
  const [newParameter, setNewParameter] = useState({
    name: "",
    type: { label: "String", value: "string" },
    show: true,
  });

  useEffect(() => {
    if (props.mode === "edit") {
      const { customer_count, parameters, ...service } = props.serviceData;
      setForm({
        ...service,
        parameters: parameters.map((param) => ({
          ...param,
          type:
            returnTypeOptions.find((opt) => opt.value === param.type) ||
            returnTypeOptions[0],
        })),
      });
    }
  }, [props.serviceData, props.show]);

  useEffect(() => {
    if (!props.show) {
      setNewParameter({
        name: "",
        type: { label: "String", value: "string" },
        show: true,
      });
      setForm(initialForm);
    }
  }, [props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function onNewParameterChange(e) {
    const { name, value } = e.target;
    setNewParameter((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function addParameter() {
    const newParam = {
      ...newParameter,
      ...(props.mode === "edit" && { id: "" }),
    };

    setForm((prev) => ({
      ...prev,
      parameters: [...prev.parameters, newParam],
    }));

    setNewParameter({
      name: "",
      type: { label: "String", value: "string" },
      show: true,
    });
  }

  function removeParameter(index) {
    setForm((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((_, i) => i !== index),
    }));
  }

  function toggleShow(index) {
    setForm((prev) => ({
      ...prev,
      parameters: prev.parameters.map((param, i) =>
        i === index ? { ...param, show: !param.show } : param
      ),
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    const formData = {
      ...form,
      return_type: form.return_type,
      parameters: form.parameters.map((param) => ({
        ...param,
        type: param.type.value,
      })),
    };

    try {
      if (props.mode === "add") {
        await createServices(formData);
      } else if (props.mode === "edit") {
        await editServiceList(formData);
      }
      props.added();
      props.onHide();
      setForm(initialForm);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.mode === "add" ? "Add Service" : "Edit Service"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Service Name</Form.Label>
            <Form.Control
              type="text"
              value={form.service_name}
              name="service_name"
              onChange={onChange}
            />
          </Form.Group>

          {/* Parameter input */}
          <div className="d-flex align-items-center" style={{ gap: "10px" }}>
            <div className="w-100 me-4">
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Input Parameter Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newParameter.name}
                  name="name"
                  onChange={onNewParameterChange}
                />
              </Form.Group>
            </div>
            <div className="w-100">
              <Form.Group className="mb-3">
                <Form.Label>Input Parameter Type</Form.Label>
                <CreatableSelect
                  value={newParameter.type}
                  onChange={(newValue) =>
                    setNewParameter((prev) => ({
                      ...prev,
                      type: newValue,
                    }))
                  }
                  options={returnTypeOptions}
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.Check
                type="switch"
                id="custom-switch-1"
                checked={newParameter.show}
                onChange={() =>
                  setNewParameter((prev) => ({ ...prev, show: !prev.show }))
                }
              />
            </Form.Group>
            <Button
              icon={addIcon}
              size="equal"
              onClick={addParameter}
              buttonMode="button"
            />
          </div>

          {/* Existing parameters list */}
          <div className="services-admin-list-input">
            <div class="scroll-height">
              {form.parameters.map((param, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between w-100 parameter-card"
                >
                  <span style={{ display: "flex", gap: "5px" }}>
                    <span>
                      {param.name}/{param.type.label}
                    </span>
                    <span className="form-check form-switch">
                      <input
                        className="form-check-input toggle-switch toggle-edit-switch"
                        type="checkbox"
                        checked={param.show}
                        onChange={() => toggleShow(index)}
                      />
                    </span>
                  </span>
                  <span>
                    <img
                      className="closeButton"
                      src={closeIcon}
                      alt="close"
                      width={20}
                      height={20}
                      onClick={() => removeParameter(index)}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Return type select */}
          <Form.Group className="mb-3">
            <Form.Label>Return Type</Form.Label>
            <Form.Select
              value={form.return_type}
              name="return_type"
              onChange={onChange}
            >
              <option value="String">String</option>
              <option value="Integer">Integer</option>
              <option value="Boolean">Boolean</option>
            </Form.Select>
            {/* <CreatableSelect
              value={form.return_type}
              onChange={(newValue) =>
                setForm((prev) => ({
                  ...prev,
                  return_type: newValue,
                }))
              }
              options={returnTypeOptions}
            /> */}
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ServicePopup;
