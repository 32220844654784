import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../Components/Button";
import { createCustomerDevice, editCustomerDevice } from "../../../Service";

const initialForm = {
  device_id: "",
  device_alias: "",
};

const validationSchema = Yup.object({
  device_id: Yup.string().required("Device Id is required"),
  device_alias: Yup.string().required("Device Alias is required"),
});

const MyDevicePopup = (props) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (props.mode === "edit") {
      setForm(props.tableData);
      formik.setValues(props.tableData);
    }

    return () => {
      setForm(initialForm);
    };
  }, [props.mode, props.show]);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (props.mode === "add") {
        try {
          await createCustomerDevice(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      } else if (props.mode === "edit") {
        try {
          await editCustomerDevice(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  // function onChange(e) {
  //   const { name, value } = e.target;
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // }

  // async function onSubmit(e) {
  //   e.preventDefault();
  //   if (props.mode === "add") {
  //     try {
  //       const data = await createCustomerDevice(form);
  //       props.added();
  //       props.onHide();
  //       setForm(initialForm);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   } else if (props.mode === "edit") {
  //     try {
  //       const data = await editCustomerDevice(form);
  //       props.added();
  //       props.onHide();
  //       setForm(initialForm);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Device ID</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.device_id}
                name="device_id"
                value={formik.values.device_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.device_id && formik.touched.device_id
                }
                disabled={props.mode === "edit"}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.device_id}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label>Alias</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder=""
                // value={form.device_alias}
                name="device_alias"
                value={formik.values.device_alias}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.device_alias && formik.touched.device_alias
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.device_alias}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={`${props.mode === "edit" ? "Update" : "Save"}`}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MyDevicePopup;
