import React, { useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "../../../Components/Button";
import { createCustomerList, editCustomerList } from "../../../Service";
import { InputGroup } from "react-bootstrap";

const initialForm = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phone: "",
  address_1: "",
  address_2: "",
  state: "",
  city: "",
  country: "",
  zipcode: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  address_1: Yup.string().required("Address 1 is required"),
  address_2: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  zipcode: Yup.string(),
});

const CustomerPopup = (props) => {
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const formik = useFormik({
    initialValues: initialForm,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (props.mode === "add") {
        try {
          await createCustomerList(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      } else if (props.mode === "edit") {
        try {
          await editCustomerList(values);
          props.added();
          props.onHide();
          resetForm();
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  useEffect(() => {
    if (props.mode === "edit") {
      formik.setValues(props.customerId);
    }
  }, [props.mode, props.customerId, props.show]);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "https://countriesnow.space/api/v0.1/countries"
        );
        setCountry(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    if (formik.values.country !== "") {
      const fetchState = async () => {
        try {
          const response = await axios.get(
            `https://countriesnow.space/api/v0.1/countries/states/q?country=${formik.values.country}`
          );
          setState(response.data.data.states);
        } catch (err) {
          console.log(err);
        }
      };
      fetchState();
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.state !== "") {
      const fetchCity = async () => {
        try {
          const response = await axios.get(
            `https://countriesnow.space/api/v0.1/countries/state/cities/q?country=${formik.values.country}&state=${formik.values.state}`
          );
          setCity(response.data.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchCity();
    }
  }, [formik.values.state]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={formik.resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.mode === "edit" ? "Edit Customer" : "Add Customer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.first_name && formik.touched.first_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.last_name && formik.touched.last_name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email && formik.touched.email}
                disabled={props.mode === "edit"}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.phone && formik.touched.phone}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formAddress1">
            <Form.Label>Address 1</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="address_1"
                value={formik.values.address_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.address_1 && formik.touched.address_1
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_1}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formAddress1">
            <Form.Label>Address 2</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                name="address_2"
                value={formik.values.address_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={
                  !!formik.errors.address_2 && formik.touched.address_2
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_2}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          {/* Country, State, City, Zip Code */}
          <div className="grid-1fr ">
            <Form.Group className="">
              <Form.Label>Country</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.country && formik.touched.country}
                >
                  <option value="">Select</option>
                  {country.map((data) => (
                    <option key={data.country} value={data.country}>
                      {data.country}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.country}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group className="">
              <Form.Label>State</Form.Label>
              <Form.Select
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
              >
                <option value="">Select</option>
                {state.map((data) => (
                  <option key={data.name} value={data.name}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Select
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              >
                <option value="">Select</option>
                {city.map((data) => (
                  <option key={data} value={data}>
                    {data}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formZipcode">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                name="zipcode"
                value={formik.values.zipcode}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3" controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={!!formik.errors.password && formik.touched.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button
              type="primary"
              label={props.mode === "edit" ? "Update" : "Save"}
              buttonMode="submit"
            />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerPopup;
