import React, { useEffect, useState } from "react";
import "./style.css";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../Components/Button";
import {
  createCustomerNotification,
  updateCustomerNotification,
} from "../../../Service";
import close from "../../../Assets/Images/close-parameter-com.svg";
import addIcon from "../../../Assets/Images/add-icon.svg";

const initialForm = {
  name: "",
  parameter: [],
};

const NotificationPopup = (props) => {
  const [form, setForm] = useState(initialForm);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.mode === "edit") {
      setForm({
        name: props.tableData.group_name,
        parameter: props.tableData.parameters,
      });
    }

    return () => {
      setForm(initialForm);
    };
  }, [props.mode, props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  // Handle input change for parameter field
  function onInputChange(e) {
    setInputValue(e.target.value);
  }

  // Add a parameter to the array
  function addParameter() {
    if (inputValue.trim()) {
      setForm((prev) => ({
        ...prev,
        parameter: [...prev.parameter, inputValue.trim()],
      }));
      setInputValue(""); // Clear input field
    }
  }

  // Remove a parameter from the array
  function removeParameter(index) {
    setForm((prev) => ({
      ...prev,
      parameter: prev.parameter.filter((_, i) => i !== index),
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    if (props.mode === "add") {
      const request_body = {
        alert_type: props.type,
        group_name: form.name,
        parameters: form.parameter,
      };
      try {
        const data = await createCustomerNotification(request_body);
        props.added();
        props.onHide();
        setForm(initialForm);
      } catch (err) {
        console.log(err);
      }
    } else if (props.mode === "edit") {
      const request_body = {
        alert_type: props.type,
        group_name: form.name,
        parameters: form.parameter,
        id: props.tableData.id,
      };
      try {
        const data = await updateCustomerNotification(request_body);
        props.added();
        props.onHide();
        setForm(initialForm);
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add {props.type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.name}
              name="name"
              onChange={onChange}
            />
          </Form.Group>

          <div className="d-flex align-items-end" style={{ gap: "10px" }}>
            <Form.Group className="mb-3 w-100" controlId="formBasicEmail">
              <Form.Label>
                {props.type === "Email" ? "Email" : "Phone"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={inputValue}
                onChange={onInputChange}
              />
            </Form.Group>
            <div className="mb-3">
              <Button
                icon={addIcon}
                size="equal"
                onClick={addParameter}
                buttonMode="button"
              />
            </div>
          </div>

          <div className="services-admin-list-input">
            <div className="scroll-height">
              <div className="parameter-wrapper-notification">
                {form.parameter.map((param, index) => (
                  <span key={index} className="email-content-wrapper">
                    {param}
                    <img
                      src={close}
                      alt="close"
                      height={20}
                      width={20}
                      className="close-icon-parameter"
                      onClick={() => removeParameter(index)}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button type="primary" label="Save" buttonMode="submit" />
            <Button onClick={props.onHide} label="Close" buttonMode="button" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NotificationPopup;
