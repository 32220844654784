import React from "react";
import "./style.css";
import logo from "../../Assets/Images/d-logo.png";
import logoSmall from "../../Assets/Images/logo-1.png";
import bxMenu from "../../Assets/Images/bx-menu.svg";
import profile from "../../Assets/Images/profile-img.svg";
import device from "../../Assets/Images/my-devices.svg";
import adminService from "../../Assets/Images/admin-services-icon.svg";
import report from "../../Assets/Images/reports.svg";
import ticket from "../../Assets/Images/raise-tickets.svg";
import notification from "../../Assets/Images/notification.svg";
import logout from "../../Assets/Images/logout.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import authService from "../../Service/authService";

const SidemenuAdmin = () => {
  const activePage = useSelector((state) => state.dmon.activePage);
  const goto = useNavigate();
  return (
    <div className="sidebar sidebar-position">
      <div className="logo-details py-3">
        <span className="d-logo">
          <img src={logo} alt="" width={40} height={40} />
        </span>
        <span className="full-logo">
          <div>
            <img src={logoSmall} alt="project Logo" />
          </div>
        </span>
        <span className="bx-menu">
          <img src={bxMenu} alt="" width={12} height={13} />
        </span>
      </div>

      <ul
        className="nav-links sidebar-accordion accordion mt-5"
        id="accordionExample"
      >
        <li
          onClick={() => {
            goto("/admin");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "customer" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={profile} alt="" />
            </div>
            <span className="link_name ">Customer</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Customer</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/devices");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "device" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={device} alt="" />
            </div>
            <span className="link_name ">Devices</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Devices</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/services");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "service" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={adminService} alt="" />
            </div>
            <span className="link_name ">Services</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Services</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/reports");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "report" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={report} alt="" />
            </div>
            <span className="link_name ">Reports</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Reports</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/tickets");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "ticket" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={ticket} alt="" />
            </div>
            <span className="link_name ">Tickets</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Tickets</div>
            </li>
          </ul>
        </li>
        <li
          onClick={() => {
            goto("/admin/notification");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "notification" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={notification} alt="" />
            </div>
            <span className="link_name">Notification</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Notification</div>
            </li>
          </ul>
        </li>
        <li
          onClick={async () => {
            await authService.logout();
            goto("/");
          }}
        >
          <div className="sidemenu-wrapper">
            <div className="icon-box">
              <img src={logout} alt="" />
            </div>
            <span className="link_name">Logout</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Logout</div>
            </li>
          </ul>
        </li>
      </ul>
      <div className="w-100 copyright-position">
        <p className="copy-right">Copyright &copy; 2024. All right reserved.</p>
      </div>
    </div>
  );
};

export default SidemenuAdmin;
