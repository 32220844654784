import React from "react";
import "./style.css"; // Import the CSS file for styling

const Battery = ({ percentage }) => {
  // Set color based on percentage
  const batteryColor =
    percentage > 50 ? "linear-gradient(180deg,  #24D4B0, #1C66B4)" : percentage > 20 ? "linear-gradient(180deg, #40CDE8, #80E7F0)" : "linear-gradient(180deg, red, #FF6F6F)";

  return (
    <div className="battery-container">
      <div className="battery-head" />
      <div className="battery-body">
        <div
          className="battery-level"
          style={{
            // width: `${percentage}%`,
            height: `${percentage}%`,
            background: batteryColor,
          }}
        />
      </div>
      <span className="battery-text">{percentage}%</span>
    </div>
  );
};

export default Battery;
