export const formatDate = (data) => {
  const date = new Date(data);

  // Format the date first
  const formattedDate = date.toLocaleString("en-GB", {
    year: "numeric",
    month: "short", // Use short month name like 'Sep'
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Replace the ', ' separator between the date and time with ':'
  const finalFormat = formattedDate.replace(", ", ":");

  return finalFormat;
};
