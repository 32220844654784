import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import WanStatusGauge from "../../../Components/WanStatusGauge";
import TopTenChart from "../../../Components/TopTenChart";
import { getDashboard } from "../../../Service";
import ThirtyDayGraph from "../../../Components/ThirtyDayGraph";
import Battery from "../../../Components/Battery";
import NetworkSpeed from "../../../Components/NetworkSpeed";

const Dashboard = () => {
  const [data, setData] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage("dashboard"));
  }, [dispatch]);

  useEffect(() => {
    const fetchDeviceList = async () => {
      try {
        const data = await getDashboard();
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDeviceList();
  }, []);
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
        <h4 className="sub-title">Overview</h4>
      </div>

      <div className="first-section">
        {/* <div className=" first-box"> */}
        <div
          className="bg-white bg-space-radius dashboard-card-wraper"
          style={{ height: "390px", width: "100%" }}
        >
          <div className="mb-4 pb-2 graph-heading">SLA LAST 24 HOURS</div>
          <WanStatusGauge
            value={
              data.overall_percentage ? Number(data.overall_percentage) : 0
            }
          />
        </div>
        <div
          className="bg-white bg-space-radius dashboard-card-wraper"
          style={{ height: "390px", width: "100%" }}
        >
          <div className="mb-4 pb-2 graph-heading"> UPS Battery Percentage</div>
          <Battery
            percentage={
              data.ups_percentage !== undefined ? data.ups_percentage : 0
            }
          />
        </div>
        {/* </div> */}
        <div
          className="bg-white bg-space-radius dashboard-card-wraper table-style-dashboard"
          style={{ height: "390px", width: "100%" }}
        >
          <div className="mb-4 pb-2 graph-heading"> Top Alerting Host</div>
          <TopTenChart data={data.top_nodes ? data.top_nodes : []} />
        </div>

        {/*  */}
      </div>
      {/* <div className="first-section mt-1">
        
      </div> */}
      <div className="d-flex align-items-center" style={{ gap: "15px" }}>
        <div className="bg-white bg-space-radius mb-4 w-100">
          <div className="mb-4 pb-2 graph-heading"> Download Speed</div>
          <NetworkSpeed chartData={data.internet_download_speed} />
        </div>
        <div className="bg-white bg-space-radius mb-4 w-100">
          <div className="mb-4 pb-2 graph-heading"> Upload Speed</div>
          <NetworkSpeed chartData={data.internet_upload_speed} />
        </div>
      </div>
      <div className="bg-white bg-space-radius">
        <div className="mb-4 pb-2 graph-heading"> SLA- Past 30 Days</div>
        <ThirtyDayGraph data={data.thirty_days_data} />
      </div>
    </div>
  );
};

export default Dashboard;
