import React from "react";
import "../SidemenuAdmin/style.css";
import logo from "../../Assets/Images/d-logo.png";
import logoSmall from "../../Assets/Images/logo-1.png";
import bxMenu from "../../Assets/Images/bx-menu.svg";
import dashboard from "../../Assets/Images/dashboard.svg";
import profile from "../../Assets/Images/profile-img.svg";
import device from "../../Assets/Images/my-devices.svg";
import monitoring from "../../Assets/Images/monitoring.svg";
import report from "../../Assets/Images/reports.svg";
import nodes from "../../Assets/Images/objects-menu.svg";
import ticket from "../../Assets/Images/raise-tickets.svg";
import notification from "../../Assets/Images/notification.svg";
import logout from "../../Assets/Images/logout.svg";
import authService from "../../Service/authService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SideMenuCustomer = () => {
  const activePage = useSelector((state) => state.dmon.activePage);
  const goto = useNavigate();
  return (
    <div className="sidebar">
      <div className="logo-details py-3">
        <span className="d-logo">
          <img src={logo} alt="" width={40} height={40} />
        </span>
        <span className="full-logo">
          <img src={logoSmall} alt="project Logo" />
        </span>
        <span className="bx-menu">
          <img src={bxMenu} alt="" width={12} height={13} />
        </span>
      </div>

      <ul
        className="nav-links sidebar-accordion accordion mt-5"
        id="accordionExample"
      >
        <li
          onClick={() => {
            goto("/client");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "dashboard" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={dashboard} alt="" />
            </div>
            <span className="link_name ">Dashboard</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Dashboard</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/profile");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "profile" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={profile} alt="" />
            </div>
            <span className="link_name ">Profile</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Profile</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/my-device");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "my-device" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={device} alt="" />
            </div>
            <span className="link_name ">My Devices</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">My Devices</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/monitoring");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "monitoring" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={monitoring} alt="" />
            </div>
            <span className="link_name ">Monitoring</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Monitoring</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/report");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "report" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={report} alt="" />
            </div>
            <span className="link_name ">Reports</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Reports</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/nodes");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "nodes" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={nodes} alt="" />
            </div>
            <span className="link_name ">Nodes</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Nodes</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/raise-ticket");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "raise-ticket" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={ticket} alt="" />
            </div>
            <span className="link_name ">Raise Tickets</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Raise Tickets</div>
            </li>
          </ul>
        </li>

        <li
          onClick={() => {
            goto("/client/notification");
          }}
        >
          <div
            className={`sidemenu-wrapper ${
              activePage === "notification" ? "active-sidemenu" : ""
            } `}
          >
            <div className="icon-box">
              <img src={notification} alt="" />
            </div>
            <span className="link_name ">Notification</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Notification</div>
            </li>
          </ul>
        </li>

        <li
          onClick={async () => {
            await authService.logout();
            goto("/");
          }}
        >
          <div className="sidemenu-wrapper">
            <div className="icon-box">
              <img src={logout} alt="" />
            </div>
            <span className="link_name">Logout</span>
          </div>
          <ul className="sub-menu close blank">
            <li>
              <div className="link_name">Logout</div>
            </li>
          </ul>
        </li>
      </ul>
      <div className="w-100 mt-4 bottom-nav">
        <p className="copy-right">Copyright &copy; 2024. All right reserved.</p>
      </div>
    </div>
  );
};

export default SideMenuCustomer;
