import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "../../../../Components/Button";
import { editMonitoringTask, getServiceList } from "../../../../Service";

const initialForm = {
  name: "",
  service_name: "",
  parameters: {},
  threshold_condition: "",
  severity: "",
  threshold_value: "",
};
const MonitoringTaskPopup = (props) => {
  const [form, setForm] = useState(initialForm);
  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        const data = await getServiceList();
        setData(data.services);
      } catch (error) {
        console.log(error);
      }
    };
    fetchServiceList();
  }, []);

  useEffect(() => {
    if (props.data && data.length > 0) {
      // Safely handle undefined or empty parameters array
      const parameters = (props.data.parameters || []).reduce((acc, param) => {
        acc[param.parameter_id] = param.parameter_value;
        return acc;
      }, {});

      setForm({
        name: props.data.name || "",
        service_name: props.data.service_name || "",
        parameters: parameters, // Set parameters
        threshold_condition: props.data.threshold?.condition || "",
        severity: props.data.severity || "",
        threshold_value: props.data.threshold?.value || "",
      });

      // Find and set the selected service
      const selectedService = data.find(
        (service) => service.service_name === props.data.service_name
      );
      if (selectedService) {
        setSelectedService(selectedService); // Set the selected service
      }
    }
  }, [props.data, data, props.show]);

  function onChange(e) {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  // Handle service selection change
  function handleServiceChange(e) {
    const selectedService = data.find(
      (service) => service.service_name === e.target.value
    );
    setSelectedService(selectedService); // Set the selected service
    setForm((prev) => ({
      ...prev,
      service_name: selectedService?.service_name || "",
      parameters: {}, // Reset parameters
      threshold_condition:
        selectedService.return_type === "Boolean"
          ? "True"
          : selectedService.return_type === "Integer"
          ? "Greater Than"
          : selectedService.return_type === "String"
          ? "Equal To"
          : "", // Reset threshold condition
    }));
  }

  // Handle parameter input change
  function handleParameterChange(id, value) {
    setForm((prev) => ({
      ...prev,
      parameters: {
        ...prev.parameters,
        [id]: value, // Map each parameter ID to its value
      },
    }));
  }

  // Handle return_type select value change
  function handleReturnTypeChange(e) {
    setForm((prev) => ({
      ...prev,
      threshold_condition: e.target.value, // Set the threshold condition
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();

    console.log(form.parameters, "Parameters");

    // Prepare parameters for the request body
    const parametersArray = Object.keys(form.parameters).map((key) => ({
      id: key, // The parameter ID
      value: form.parameters[key], // The parameter value
    }));

    const filteredArray = parametersArray.filter(
      (parameter) => parameter.id !== "null"
    );

    // Construct the request body
    const requestBody = {
      task_id: props.id,
      name: form.name,
      service_name: form.service_name,
      severity: form.severity,
      threshold: {
        threshold_condition: form.threshold_condition,
        threshold_value:
          selectedService.return_type !== "Boolean" ? form.threshold_value : "",
      },
      // parameters: parametersArray[0]?.id !== "null" ? parametersArray : [], // Array of parameter objects
      parameters: filteredArray,
    };
    try {
      const data = await editMonitoringTask(requestBody);
      //   props.added();
      props.success();
      props.onHide();
      setForm(initialForm);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Description
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Task Name</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={form.name}
              name="name"
              onChange={onChange}
            />
          </Form.Group>

          {/* Select to choose which service to monitor */}
          <Form.Group className="mb-3">
            <Form.Label>Service Name</Form.Label>
            <Form.Select
              value={form.service_name}
              name="service_name"
              onChange={handleServiceChange}
            >
              <option value="">Select</option>
              {data.map((service) => (
                <option key={service.service_id} value={service.service_name}>
                  {service.service_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {/* Dynamically render inputs based on parameters */}
          {selectedService?.parameters.map((param) => (
            <Form.Group key={param.id} className="mb-3">
              <Form.Label>{param.name}</Form.Label>
              <Form.Control
                type="text"
                placeholder={`Enter ${param.name}`}
                value={form.parameters[param.id] || ""} // Set value from form.parameters
                onChange={(e) =>
                  handleParameterChange(param.id, e.target.value)
                }
              />
            </Form.Group>
          ))}

          <Form.Group className="mb-3">
            <Form.Label>Severity Levels</Form.Label>
            <Form.Select
              value={form.severity}
              name="severity"
              onChange={onChange}
            >
              <option value="">Select an option</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </Form.Select>
          </Form.Group>

          {/* Render select box based on return_type */}
          {selectedService && (
            <div className="grid-1fr">
              <Form.Group className="mb-3">
                <Form.Label>Threshold Condition</Form.Label>
                {selectedService.return_type === "Boolean" ? (
                  <Form.Select
                    value={form.threshold_condition}
                    onChange={handleReturnTypeChange}
                  >
                    <option value="True">True</option>
                    <option value="False">False</option>
                  </Form.Select>
                ) : selectedService.return_type === "String" ? (
                  <Form.Select
                    value={form.threshold_condition}
                    onChange={handleReturnTypeChange}
                  >
                    <option value="Equal To">Equal To</option>
                    <option value="Contains">Contains</option>
                    <option value="Does not Contain">Does not Contain</option>
                  </Form.Select>
                ) : selectedService.return_type === "Integer" ? (
                  <Form.Select
                    value={form.threshold_condition}
                    onChange={handleReturnTypeChange}
                  >
                    <option value="Greater Than">Greater Than</option>
                    <option value="Less Than">Less Than</option>
                    <option value="Equal To">Equal To</option>
                    <option value="Between">Between</option>
                  </Form.Select>
                ) : null}
              </Form.Group>

              {selectedService.return_type !== "Boolean" && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Value</Form.Label>
                  <Form.Control
                    type={`${
                      selectedService.return_type === "Integer"
                        ? "number"
                        : "text"
                    }`}
                    placeholder=""
                    value={form.threshold_value}
                    name="threshold_value"
                    onChange={onChange}
                  />
                </Form.Group>
              )}
            </div>
          )}

          <div className="d-flex justify-content-end" style={{ gap: "15px" }}>
            <Button type="primary" label="Submit" buttonMode="submit" />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MonitoringTaskPopup;
