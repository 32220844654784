import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import editIcon from "../../../Assets/Images/edit-icon.svg";
import deleteIcon from "../../../Assets/Images/delete.svg";
import addIcon from "../../../Assets/Images/add-white.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import Button from "../../../Components/Button";
import { useDispatch } from "react-redux";
import { setActivePage } from "../../../Redux/reducer";
import { deleteCustomerNodes, getCustomerNodes } from "../../../Service";
import NodesPopup from "./NodesPopup";
import AlertBox from "../../../Components/AlertBox";
import { formatDate } from "../../../Utils";

const Nodes = () => {
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mode, setMode] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState("");
  const [deleteData, setDeleteData] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const data = await getCustomerNodes();
        setData(data.objects);
      } catch (error) {
        console.log(error);
      }
    };
    dispatch(setActivePage("nodes"));
    fetchCustomerList();
  }, [dispatch, isAdded]);

  function added() {
    setIsAdded(!isAdded);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "type",
        header: "Type",
      },
      {
        accessorKey: "created_at",
        header: "Created On",
        accessorFn: (row) => new Date(row.created_at), // Use Date object for sorting
        Cell: ({ cell }) => formatDate(cell.getValue()), // Format the date for display
        sortingFn: "datetime", // Ensure it sorts as a datetime field
      },
      {
        accessorKey: "value",
        header: "Value",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row, table }) => (
      <Box
        sx={{ display: "flex", gap: "1rem", minWidth: "100px", width: "100px" }}
      >
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setMode("edit");
              setTableData(row.original);
              setModalShow(true);
            }}
          >
            <img src={editIcon} alt="edit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => {
              setAlert(true);
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerNodes({
                    object_ids: [row.original.id],
                  });
                  setAlert(false);
                  added();
                },
              });
            }}
          >
            <img src={deleteIcon} alt="delete" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="d-flex btn-space" style={{gap: '15px'}}>
        <Button
          onClick={() => {
            setMode("add");
            setModalShow(true);
          }}
          icon={addIcon}
          label="Add"
          type="primary"
        />

        {table.getSelectedRowModel().rows.length > 0 && (
          <Button
            onClick={() => {
              const selectedRows = table.getSelectedRowModel().rows;
              const newArray = selectedRows.map((rows) => {
                return rows.original.id;
              });
              setDeleteData({
                onClick: async () => {
                  await deleteCustomerNodes({ object_ids: newArray });
                  setAlert(false);
                  setRowSelection({});
                  added();
                },
              });
              setAlert(true);
            }}
            icon={deleteIcon}
            label="Delete"
            type="secondary"
          />
        )}
      </div>
    ),
  });

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between mb-2 respo-flex">
          <h4 className="sub-title">Nodes</h4>
        </div>
        <MaterialReactTable table={table} />
      </div>
      <NodesPopup
        show={modalShow}
        mode={mode}
        tableData={tableData}
        added={added}
        onHide={() => setModalShow(false)}
      />

      <AlertBox
        show={alert}
        onHide={() => setAlert(false)}
        deleteData={deleteData}
      />
    </>
  );
};

export default Nodes;
